<template>
  <div class="main-footer">
    <footer class="all-footer">
      <h4>بدعم من اوقاف الضحيان</h4>
      <img src="@/assets/photos/logo-preview.png" alt="" />
      <h4>Made with <span>T</span> ECH LAB</h4>
    </footer>
  </div>
</template>
<script>
export default {
  name: "FooterBar",
};
</script>
