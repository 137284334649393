<template>
  <div class="employees-add">
    <div class="plus">
      <i class="fa-solid fa-plus"></i>
      <HeaderPages title="اضافة موظف" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              type="file"
              id="avatar"
              class="form-control"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <!-- <span class="error-feedback" v-if="v$.form.imageSrc.$error">{{
              getErrorMessage(v$.form.imageSrc)
            }}</span> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الموظف</label>
          <div class="input">
            <input
              type="text"
              id="name"
              class="form-control"
              placeholder="أدخل أسم الموظف"
              v-model="form.name"
              @keypress="onlyAllowLetters"
            />
            <span class="error-feedback" v-if="v$.form.name.$error">{{
              getErrorMessage(v$.form.name)
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="phone">رقم الهاتف</label>
          <div class="input">
            <input
              type="text"
              id="phone"
              class="no-spinner"
              placeholder="أدخل رقم الهاتف"
              v-model="form.phone"
              @keypress="onlyAllowNumbers"
            />
            <span class="error-feedback" v-if="v$.form.phone.$error">{{
              getErrorMessage(v$.form.phone)
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="email">البريد الالكتروني</label>
          <div class="input">
            <input
              type="email"
              id="email"
              placeholder="أدخل البريد الالكتروني"
              v-model="form.email"
              @input="validateEmail"
            />
            <span class="error-feedback" v-if="v$.form.email.$error">{{
              getErrorMessage(v$.form.email)
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="password">الرقم السري</label>
          <div class="input">
            <div class="pas">
              <input
                :type="showPassword ? 'text' : 'password'"
                id="password"
                placeholder="أدخل الرقم السري"
                v-model="form.password"
              />
              <i
                class="fa-solid"
                :class="showPassword ? 'fa-eye-slash' : 'fa-eye'"
                @click="togglePasswordVisibility"
                style="cursor: pointer"
              ></i>
            </div>
            <span class="error-feedback" v-if="v$.form.password.$error">
              {{ getErrorMessage(v$.form.password) }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="roles">الصلاحيات</label>
          <multiselect
            id="roles"
            deselect-label=""
            select-label=""
            :close-on-select="true"
            v-model="form.role"
            :options="permissionOptions"
            :multiple="true"
            placeholder="ادخل الصلاحيات"
          ></multiselect>
          <span class="error-feedback" v-if="v$.form.role.$error">{{
            getErrorMessage(v$.form.role)
          }}</span>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import { useEmployeesAddStore } from "@/stores/employees/EmployeesAddStore";
import "vue-multiselect/dist/vue-multiselect.css";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import Swal from "sweetalert2";

// import Swal from "sweetalert2";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      rolesOptions: ["Admin", "Manager", "Employee"],
      showPassword: false,

      v$: useVuelidate(),
      form: {
        name: "",
        phone: "",
        email: "",
        password: "",
        role: "",
        // image: null,
        // imageSrc: "",
      },
      permissionOptions: ["list", "of", "options"],
    };
  },

  validations() {
    return {
      form: {
        // imageSrc: { required },
        name: { required },
        phone: { required },
        email: { required, email },
        password: { required },
        role: { required },
      },
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    async submitForm() {
      this.v$.$validate();
      if (this.v$.$invalid) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "يرجى ملء جميع الحقول المطلوبة",
        });
        return;
      }
      try {
        const employeesStore = useEmployeesAddStore();
        if (!employeesStore) {
          throw new Error("Failed to load employees store");
        }
        if (
          !this.form.name ||
          !this.form.phone ||
          !this.form.email ||
          !this.form.password ||
          !this.form.role
        ) {
          // Swal.fire("Error", "Please fill in all fields", "error");
          return;
        }
        await employeesStore.addEmployee(this.form); // Call addEmployee instead of fetchEmployees
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
    onlyAllowLetters(event) {
      const char = String.fromCharCode(event.keyCode);
      const regex = /^[\u0621-\u064A\u0660-\u0669a-zA-Z\s]+$/; // يسمح بالحروف العربية والإنجليزية والمسافات
      if (!regex.test(char)) {
        event.preventDefault();
        Swal.fire("خطأ", "لا يُسمح بإدخال الأرقام في هذا الحقل", "error");
      }
    },
    onlyAllowNumbers(event) {
      const char = String.fromCharCode(event.keyCode);
      const regex = /^[0-9]+$/; // يسمح فقط بالأرقام
      if (!regex.test(char)) {
        event.preventDefault();
        Swal.fire("خطأ", "لا يُسمح إلا بإدخال الأرقام في هذا الحقل", "error");
      }
    },
    validateForm() {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      // التحقق من صحة البريد الإلكتروني
      if (!this.form.email || !emailPattern.test(this.form.email)) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "البريد الالكتروني غير صالح",
        });
        return false;
      }

      return true; // إذا كان البريد الإلكتروني صحيحًا
    },
  },
};
</script>
<style scoped>
#password {
  border: 0 !important;
}
.pas {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.164);
  border-radius: 15px;
}
.no-spinner::-webkit-inner-spin-button,
.no-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.avatar-preview {
  position: relative;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.error-feedback {
  color: red;
  font-size: 0.85rem;
}
</style>
